import React, { useEffect, useState } from 'react';
import NavLogo from '../../../components/NavLogo';
import Logo from '../../../components/Logo';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DynamicNavLogo from '../../../components/DynamicNavLogo';
import CustomTabs from '../../../layouts/CustomTabs';
import { retrieveAtsAnalyticsReset, retrieveDataAnalyticsReset, retrieveEntityDetailsReset, retrieveProducerAnalyticsReset, retrieveSpecificEntityProfileReportsReset, retrieve_data_analytics_overview, retrieve_entity_stats, retrieve_exchange_ats_analytics, retrieve_exchange_producer_analytics, retrieve_exchange_warehouse_analytics } from '../../../utils/actions';
import * as app_consts from '../../../utils/constants';
import { convertToTitleCase, formatNumber, replace_string_with_forward_slash } from '../../../utils/functionalUtils';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as chartjs, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, BarElement, ArcElement } from "chart.js";
import VerticalCustomTabs from '../../../layouts/VerticalCustomTabs';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

chartjs.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, BarElement, ArcElement);

const AnalyticsDashboard = () => {

  const { entity_id, entity_mid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Overview');
  const [overview_loading, setOverviewLoading] = useState(true);
  const [overview_error, setOverviewError] = useState("");
  const [overview_data, setOverviewData] = useState("");

  const [entity_data, setEntityData] = useState([]);
  const [entity_loading, setEntityLoading] = useState(true);
  const [entity_error, setEntityError] = useState("");

  const [warehouse_stats_loading, setWarehouseStatsloading] = useState(false);
  const [warehouse_stats_error, setWarehouseStatsError] = useState("");
  const [warehouse_stats_data, setWarehouseStatsData] = useState([]);

  const [trading_stats_loading, setTradingStatsloading] = useState(false);
  const [trading_stats_error, setTradingStatsError] = useState("");
  const [trading_stats_data, setTradingStatsData] = useState([]);

  const [producer_stats_loading, setProducerStatsloading] = useState(false);
  const [producer_stats_error, setProducerStatsError] = useState("");
  const [producer_stats_data, setProducerStatsData] = useState([]);

  const [entity_stats_tab, setEntityStatsTab] = useState("Registration Stats");
  const [registrations_data, setRegistrationsData] = useState([]);

  const [registration_data_count, setRegistrationsDateCount] = useState([]);
  const [registration_data_labels, setRegistrationDataLabels] = useState([]);

  const [entity_category_data, setEntityCategoryData] = useState([]);
  const [entity_category_data_count, setEntityCategoryDataCount] = useState([]);

  const [offers_data, setOffersData] = useState([]);
  const [offers_data_count, setOffersDataCount] = useState([]);
  const [offers_data_labels, setOffersDataLabels] = useState([]);
  const [bids_data_count, setBidsDataCount] = useState([]);
  const [bids_data_labels, setBidsDataLabels] = useState([]);
  const [bids_on_offers_data_count, setBidsOnOffersDateCount] = useState([]);
  const [bids_on_offers_data_labels, setBidsOnOffersDataLabels] = useState([]);
  const [offers_on_bids_data_count, setOffersOnBidsDateCount] = useState([]);
  const [offers_on_bids_labels, setOffersOnBidsDataLabels] = useState([]);

  const [entity_category_status_data, setEntityCategoryStatusData] = useState([]);
  const [entity_category_status_data_count, setEntityCategoryStatusDataCount] = useState([]);
  const [entity_category_status_data_labels, setEntityCategoryStatusDataLabels] = useState([]);

  const [entity_profile_data, setEntityProfileData] = useState([]);
  const [entity_profile_data_count, setEntityProfileDataCount] = useState([]);


  const [entity_profile_status_data, setEntityProfileStatusData] = useState([]);
  const [entity_profile_status_data_count, setEntityProfileStatusDataCount] = useState([]);
  const [entity_profile_status_data_labels, setEntityProfileStatusDataLabels] = useState([]);

  const [producer_type_labels, setProducerTypeLabels] = useState([]);
  const [producer_type_values, setProducerTypeValues] = useState([]);
  const [producer_whr_pie_labels, setProducerWhrPieLabels] = useState([]);
  const [producer_whr_pie_values, setProducerWhrPieValues] = useState([]);
  const [producer_commodity_labels, setProducerCommodityLabels] = useState([]);
  const [producer_commodity_values, setProducerCommodityValues] = useState([]);
  const [warehouse_certification_labels, setWarehouseCertificationLabels] = useState([]);
  const [warehouse_certification_values, setWarehouseCertificationValues] = useState([]);
  const [warehouse_storage_labels, setWarehouseStorageLabels] = useState([]);
  const [warehouse_storage_values, setWarehouseStorageValues] = useState({ used: 0, available: 0, total: 0 });

  // Code that runs when Page Opens
  useEffect(() => {
    dispatch(retrieve_data_analytics_overview());
    dispatch(retrieve_entity_stats());
    dispatch(retrieve_exchange_warehouse_analytics());
    dispatch(retrieve_exchange_ats_analytics());
    dispatch(retrieve_exchange_producer_analytics());
  }, []);

  // Listener
  const data_overview_listener = useSelector((state) => state.retriveDataAnalyticsOverview);
  // 
  useEffect(() => {
    if (data_overview_listener.status !== app_consts.IDLE)
    {
      if (data_overview_listener.isLoading)
      {
        setOverviewLoading(true);
      }
      else
      {
        if (data_overview_listener.error)
        {
          setOverviewError(data_overview_listener.error);
        }
        else if (data_overview_listener.data)
        {
          setOverviewData(data_overview_listener.data);
        }
        dispatch(retrieveDataAnalyticsReset());
        setOverviewLoading(false);
      }
    }
  }, [data_overview_listener]);

  // Listener
  const entity_stats_listener = useSelector((state) => state.retriveEntityDetails);
  // 
  useEffect(() => {
    if (entity_stats_listener.status !== app_consts.IDLE)
    {
      if (entity_stats_listener.isLoading)
      {
        setEntityLoading(true);
      }
      else
      {
        if (entity_stats_listener.error)
        {
          setEntityError(entity_stats_listener.error);
        }
        else if (entity_stats_listener.data)
        {
          setEntityData(entity_stats_listener.data);
        }
        dispatch(retrieveEntityDetailsReset());
        setEntityLoading(false);
      }
    }
  }, [entity_stats_listener]);

  // Listener
  const warehouse_stats_listener = useSelector((state) => state.retrieveSpecificEntityProfileReports);
  // 
  useEffect(() => {
    if (warehouse_stats_listener.status !== app_consts.IDLE)
    {
      if (warehouse_stats_listener.isLoading)
      {
        setWarehouseStatsloading(true);
      }
      else
      {
        if (warehouse_stats_listener.error)
        {
          setWarehouseStatsError(warehouse_stats_listener.error);
        }
        else if (warehouse_stats_listener.data)
        {
          setWarehouseStatsData(warehouse_stats_listener.data);
        }
        dispatch(retrieveSpecificEntityProfileReportsReset());
        setWarehouseStatsloading(false);
      }
    }
  }, [warehouse_stats_listener]);

  // Listener
  const trading_stats_listener = useSelector((state) => state.retrieveAtsAnalytics);
  // 
  useEffect(() => {
    if (trading_stats_listener.status !== app_consts.IDLE)
    {
      if (trading_stats_listener.isLoading)
      {
        setTradingStatsloading(true);
      }
      else
      {
        if (trading_stats_listener.error)
        {
          setTradingStatsError(trading_stats_listener.error);
        }
        else if (trading_stats_listener.data)
        {
          setTradingStatsData(trading_stats_listener.data);
        }
        dispatch(retrieveAtsAnalyticsReset());
        setTradingStatsloading(false);
      }
    }
  }, [trading_stats_listener]);

  // Listener
  const producer_stats_listener = useSelector((state) => state.retrieveProducerAnalytics);
  // 
  useEffect(() => {
    if (producer_stats_listener.status !== app_consts.IDLE)
    {
      if (producer_stats_listener.isLoading)
      {
        setProducerStatsloading(true);
      }
      else
      {
        if (producer_stats_listener.error)
        {
          setProducerStatsError(producer_stats_listener.error);
        }
        else if (producer_stats_listener.data)
        {
          setProducerStatsData(producer_stats_listener.data);
        }
        dispatch(retrieveProducerAnalyticsReset());
        setProducerStatsloading(false);
      }
    }
  }, [producer_stats_listener]);

  // UseEffect for Entity Data
  useEffect(() => {
    if (entity_data)
    {
      // Set Registration Data
      filterRegistrationData();
      filterEntityCategoryData();
      filterEntityCategoryStatusData();
      filterEntityProfileData();
      filterEntityProfileStatusData();
    }
  }, [entity_data]);

  // Set Registration Data
  const filterRegistrationData = (period = null) => {
    let data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      data = entity_data.map((entity) => entity?.inserted_at);
      // Group Dates By Year and Month
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const year_month_group = data.reduce((acc, dateStr) => {
        const date = new Date(dateStr);

        // Check if the date is valid
        if (!isNaN(date))
        {
          const yearMonth = `${date.getFullYear()}-${monthNames[date.getMonth()]}`; // Format as YYYY-MMM

          if (!acc[yearMonth])
          {
            acc[yearMonth] = 0;
          }
          acc[yearMonth]++;
        } else
        {
          console.error(`Invalid date: ${dateStr}`);
        }

        return acc;
      }, {});
      // Convert the keys of year_month_group to an array of labels
      const labels = Object.keys(year_month_group);

      // Assuming setRegistrationDataLabels expects an array
      setRegistrationDataLabels(labels);

    }
    setRegistrationsData(data);
  };

  // Set Trading Data
  const filterTradingStasData = (period = null) => {
    let bids_grouped_data = [];
    let bids_on_offers_grouped_data = [];
    let offers_grouped_data = [];
    let offers_on_bids_grouped_data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      const bids_with_offers = trading_stats_data?.bids_with_offers;
      const offers_with_bids = trading_stats_data?.offers_with_bids;
      const bids_data = bids_with_offers?.map((bid) => bid?.bid?.inserted_at);
      const offers_data = offers_with_bids?.map((offer) => offer?.offer?.inserted_at);
      const offers_on_bids_1 = bids_with_offers.flatMap(bid => bid.offers || []);
      const offers_on_bids_2 = offers_on_bids_1?.map((offer) => offer?.inserted_at);
      const bids_on_offers_1 = offers_with_bids.flatMap(offer => offer.bids || []);
      const bids_on_offers_2 = bids_on_offers_1?.map((bid) => bid?.inserted_at);

      // Group Dates By Year and Month
      offers_grouped_data = valid_dates(offers_data);
      bids_grouped_data = valid_dates(bids_data);
      offers_on_bids_grouped_data = valid_dates(offers_on_bids_2);
      bids_on_offers_grouped_data = valid_dates(bids_on_offers_2);

      // Set Labels and Values
      setOffersDataLabels(Object.keys(offers_grouped_data));
      setBidsDataLabels(Object.keys(bids_grouped_data));
      setOffersOnBidsDataLabels(Object.keys(offers_on_bids_grouped_data));
      setBidsOnOffersDataLabels(Object.keys(bids_on_offers_grouped_data));
    }
    setOffersDataCount(offers_grouped_data);
    setBidsDataCount(bids_grouped_data);
    setOffersOnBidsDateCount(offers_on_bids_grouped_data);
    setOffersOnBidsDateCount(offers_on_bids_grouped_data);
    setBidsOnOffersDateCount(bids_on_offers_grouped_data);
  };

  // Listener
  useEffect(() => {
    if (offers_data)
    {
      const groupedCategories = offers_data.reduce((acc, offer) => {
        if (!acc[offer])
        {
          acc[offer] = 0;
        }
        acc[offer]++;
        return acc;
      }, {});
      setEntityCategoryDataCount(groupedCategories);
    }
  }, [offers_data]);

  // Listen to Registrations Data
  useEffect(() => {
    if (registrations_data)
    {
      // Group Data By Dates
      const groupedDates = registrations_data.reduce((acc, dateStr) => {
        const date = new Date(dateStr);
        const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format as YYYY-MM

        if (!acc[yearMonth])
        {
          acc[yearMonth] = 0;
        }
        acc[yearMonth]++;
        return acc;
      }, {});

      setRegistrationsDateCount(groupedDates);
    }
  }, [registrations_data]);

  // Function to Check for Valid Date
  const valid_dates = (data) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year_month_group = data.reduce((acc, dateStr) => {
      const date = new Date(dateStr);


      // Check if the  date is valid
      if (!isNaN(date))
      {
        const yearMonth = `${date.getFullYear()}-${monthNames[date.getMonth()]}`; // Format as YYYY-MMM

        if (!acc[yearMonth])
        {
          acc[yearMonth] = 0;
        }
        acc[yearMonth]++;
      } else
      {
        console.error(`Invalid date: ${dateStr}`);
      }

      return acc;
    }, {});
    return year_month_group;

  };

  // Listen to Trading Data
  useEffect(() => {
    if (trading_stats_data)
    {
      if (trading_stats_data.bids_with_offers && trading_stats_data.offers_with_bids)
      {
        filterTradingStasData();
      }
    }
  }, [trading_stats_data]);

  // Filter Entity Category Data
  const filterEntityCategoryData = (period = null) => {
    let data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      data = entity_data.map((entity) => {
        if (entity?.entity_category)
        {
          return `${entity?.entity_category?.replace(/_/g, ' ').toUpperCase()}`;
        }
        else
        {
          return "Unknown";
        }
      }
      );
    }
    setEntityCategoryData(data);
  };

  // Entity Category Data Listener
  useEffect(() => {
    if (entity_category_data)
    {
      const groupedCategories = entity_category_data.reduce((acc, category) => {
        if (!acc[category])
        {
          acc[category] = 0;
        }
        acc[category]++;
        return acc;
      }, {});
      setEntityCategoryDataCount(groupedCategories);
    }
  }, [entity_category_data]);

  // 
  const filterEntityCategoryStatusData = (period = null) => {
    let data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      data = entity_data.map((entity) => entity?.status?.replace(/_/g, ' ').toUpperCase());
    }
    setEntityCategoryStatusData(data);
  };

  // 
  useEffect(() => {
    if (entity_category_status_data)
    {
      const groupedCategories = entity_category_status_data.reduce((acc, status) => {
        if (!acc[status])
        {
          acc[status] = 0;
        }
        acc[status]++;
        return acc;
      }, {});
      // Step 2: Explicitly order the keys
      const orderedCategories = ["ACTIVE", "PENDING"];
      const labels = orderedCategories.filter(category => groupedCategories[category] !== undefined);
      const values = labels.map(label => groupedCategories[label]);

      // Assuming these functions set the data for your chart
      setEntityCategoryStatusDataLabels(labels);
      setEntityCategoryStatusDataCount(values);
    }
  }, [entity_category_status_data]);

  const filterEntityProfileData = (period = null) => {
    let data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      data = entity_data.flatMap((entity) => {
        return entity.entity_profiles?.map((profile) => {
          return profile.type?.replace(/_/g, ' ').toUpperCase();
        }) || [];
      });
    }
    setEntityProfileData(data);
  };

  // 
  useEffect(() => {
    if (entity_profile_data)
    {
      const groupedCategories = entity_profile_data.reduce((acc, profile) => {
        if (!acc[profile])
        {
          acc[profile] = 0;
        }
        acc[profile]++;
        return acc;
      }, {});
      setEntityProfileDataCount(groupedCategories);
    }
  }, [entity_profile_data]);

  const filterEntityProfileStatusData = (period = null) => {
    let data = [];
    if (period)
    {
      if (period === "weekly")
      {

      }
    }
    else
    {
      data = entity_data.flatMap((entity) => {
        return entity.entity_profiles?.map((profile) => {
          return profile.status?.replace(/_/g, ' ').toUpperCase();
        }) || [];
      });
    }
    setEntityProfileStatusData(data);
  };

  // 
  useEffect(() => {
    if (entity_profile_status_data)
    {
      const groupedCategories = entity_profile_status_data.reduce((acc, profile_status) => {
        if (!acc[profile_status])
        {
          acc[profile_status] = 0;
        }
        acc[profile_status]++;
        return acc;
      }, {});
      // Step 2: Explicitly order the keys
      const orderedCategories = ["ACTIVE", "PENDING"];
      const labels = orderedCategories.filter(category => groupedCategories[category] !== undefined);
      const values = labels.map(label => groupedCategories[label]);

      // Assuming these functions set the data for your chart
      setEntityProfileStatusDataLabels(labels);
      setEntityProfileStatusDataCount(values);
    }
  }, [entity_profile_status_data]);

  // Producers Listner
  useEffect(() => {
    if (producer_stats_data)
    {
      if (producer_stats_data.product_type_counts)
      {
        const producer_type_lbls = Object.keys(producer_stats_data?.product_type_counts);
        const producer_type_lbls_transformedKeys = producer_type_lbls.map(key => key.replace(/_/g, ' ').toUpperCase());
        const producer_type_vals = Object.values(producer_stats_data?.product_type_counts);

        // Assuming these functions set the data for your chart
        setProducerTypeLabels(producer_type_lbls_transformedKeys);
        setProducerTypeValues(producer_type_vals);
      }
      if (producer_stats_data.commodity_counts)
      {

        const normalizeKey = (key) => {
          return key.toLowerCase().replace(/ /g, '_');
        };

        const groupAndSumData = (data) => {
          const normalizedData = {};

          for (const [key, value] of Object.entries(data))
          {
            const normalizedKey = normalizeKey(key);

            if (normalizedData[normalizedKey])
            {
              normalizedData[normalizedKey] += value;
            } else
            {
              normalizedData[normalizedKey] = value;
            }
          }

          return normalizedData;
        };

        const groupedData = groupAndSumData(producer_stats_data?.commodity_counts);
        const producer_commodity_lbls = Object.keys(groupedData);

        const producer_commodity_lbls_transformedKeys = producer_commodity_lbls.map(key => key.replace(/_/g, ' ').toUpperCase());
        const producer_commodity_vals = Object.values(groupedData);

        // Assuming these functions set the data for your chart
        setProducerCommodityLabels(producer_commodity_lbls_transformedKeys);
        setProducerCommodityValues(producer_commodity_vals);
      }
      const producer_with_whr_lbl = "With Warehouse Receipts";
      const producer_without_whr_lbl = "Without Warehouse Receipts";
      const producer_with_whr_val = producer_stats_data?.proucers_with_warehouse_receipts || 0;
      const producer_without_whr_val = producer_stats_data?.producers_without_warehouse_receipts || 0;
      setProducerWhrPieLabels([producer_with_whr_lbl, producer_without_whr_lbl]);
      setProducerWhrPieValues([producer_with_whr_val, producer_without_whr_val]);
    }
  }, [producer_stats_data]);

  // Warehouses Listener
  useEffect(() => {
    if (warehouse_stats_data)
    {
      const certified_warehouses_lbl = "Certified Warehouses";
      const uncertified_warehouses_lbl = "Uncertified Warehouses";
      const certified_warehouses_val = warehouse_stats_data?.certified_warehouses || 0;
      const uncertified_warehouses_val = warehouse_stats_data?.uncertified_warehouses || 0;
      setWarehouseCertificationLabels([certified_warehouses_lbl, uncertified_warehouses_lbl]);
      setWarehouseCertificationValues([certified_warehouses_val, uncertified_warehouses_val]);
      if (warehouse_stats_data.total_capacities_by_type)
      {
        const warehouse_storage_lbls = warehouse_stats_data.total_capacities_by_type?.map((storage) => convertToTitleCase(replace_string_with_forward_slash(storage?.storage_type)));
        const warehouse_available_storage_values_transformedKeys = warehouse_stats_data.total_capacities_by_type?.map((storage) => storage?.total_available_space);
        const warehouse_used_storage_values_transformedKeys = warehouse_stats_data.total_capacities_by_type?.map((storage) => storage?.total_used_capacity);
        const warehouse_total_storage_values_transformedKeys = warehouse_stats_data.total_capacities_by_type?.map((storage) => storage?.total_storage_capacity);
        setWarehouseStorageLabels(warehouse_storage_lbls);
        setWarehouseStorageValues({ available: warehouse_available_storage_values_transformedKeys, used: warehouse_used_storage_values_transformedKeys, total: warehouse_total_storage_values_transformedKeys });
      }
    }
  }, [warehouse_stats_data]);

  // retrieve_entity_stats
  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const tabs = [
    { title: 'Overview', disabled: overview_loading },
    { title: 'Entity Stats', disabled: entity_loading },
    { title: 'Warehouse Stats', disabled: warehouse_stats_loading },
    { title: 'Trading Platform Stats', disabled: trading_stats_loading },
    { title: 'Producer Stats', disabled: producer_stats_loading },
  ];

  const tabs_entity_stats = [
    { title: 'Registration Stats', disabled: false },
    { title: 'Entity Category Stats', disabled: false },
    // { title: 'User Stats', disabled: false }
  ];

  const entity_registration_data = {
    // labels: registration_data_labels,
    datasets: [{
      label: "Registrations",
      data: registration_data_count,
      backgroundColor: "green",
      borderColor: "gray",
      pointBorderColor: "green",
      fill: true,
      tension: 0.4
    }]
  };

  const entity_registration_options = {
    plugins: {
      legend: true
    },
    scales: {
      y: {
        min: 0,
        // max: 50
      }
    }
  };

  const entity_category_barchart_data = {
    // labels: ["Me", "Lol"], 
    datasets: [{
      label: "Entity Category",
      data: entity_category_data_count,
      backgroundColor: "#50C878",
      borderColor: "gray",
      borderWidth: 1
    }]
  };

  const entity_category_barchart_options = {
    // plugins: {
    //   legend: true
    // },
    // scales: {
    //   y: {
    //     min: 0,
    //     // max: 50
    //   }
    // }
  };

  const entity_category_status_piechart_data = {
    labels: entity_category_status_data_labels,
    datasets: [{
      data: entity_category_status_data_count,
      backgroundColor: ["#50C878", "#FFA500"]
    }]
  };


  const entity_category_status_piechart_options = {

  };

  const entity_category_horizontal_barchart_data = {
    // labels: ["Me", "Lol"], 
    datasets: [{
      label: "Entity Profiles",
      data: entity_profile_data_count,
      backgroundColor: "#50C878",
      borderColor: "gray",
      borderWidth: 1
    }]
  };

  const entity_category_horizontal_barchart_options = {
    indexAxis: "y"
  };

  const entity_profile_status_piechart_data = {
    labels: entity_profile_status_data_labels,
    datasets: [{
      data: entity_profile_status_data_count,
      backgroundColor: ["#50C878", "#FFA500"]
    }]
  };


  const producer_types_barchart_data = {
    labels: producer_type_labels,
    datasets: [{
      label: "Producers",
      data: producer_type_values,
      backgroundColor: "#50C878",
      borderColor: "gray",
      borderWidth: 1
    }]
  };

  const producer_types_barchart_options = {
    // plugins: {
    //   legend: true
    // },
    // scales: {
    //   y: {
    //     min: 0,
    //     // max: 50
    //   }
    // }
  };

  const entity_profile_status_piechart_options = {

  };

  const producer_warehouse_receipt_piechart_data = {
    labels: producer_whr_pie_labels,
    datasets: [{
      data: producer_whr_pie_values,
      backgroundColor: ["#50C878", "#FFA500"]
    }]
  };

  const producer_warehouse_receipt_piechart_options = {

  };

  const trading_transcation_line_data = {
    labels: producer_whr_pie_labels,
    datasets: [{
      data: producer_whr_pie_values,
      backgroundColor: ["#50C878", "#FFA500"]
    }]
  };

  const trading_transcation_line_options = {

  };

  const producer_provinces_horizontal_barchart_data = {
    // labels: ["Me", "Lol"], 
    datasets: [{
      label: "Producer Provinces",
      data: producer_stats_data?.producer_province_counts,
      backgroundColor: "#50C878",
      borderColor: "gray",
      borderWidth: 1
    }]
  };

  const producer_provinces_horizontal_barchart_options = {
    indexAxis: "y"
  };

  const warehouse_provinces_horizontal_barchart_data = {
    // labels: ["Me", "Lol"], 
    datasets: [{
      label: "Warehouse Provinces",
      data: warehouse_stats_data?.province_counts,
      backgroundColor: "#50C878",
      borderColor: "gray",
      borderWidth: 1
    }]
  };

  const warehouse_provinces_horizontal_barchart_options = {
    indexAxis: "y"
  };

  const producer_comomdities_piechart_data = {
    labels: producer_commodity_labels,
    datasets: [{
      data: producer_commodity_values,
      backgroundColor: ["gray", "#50C878", "#FFA500", "#fd5c63", "#2596be"]
    }]
  };

  const producer_comomdities_piechart_options = {

  };

  const warehouse_certification_piechart_data = {
    labels: warehouse_certification_labels,
    datasets: [{
      data: warehouse_certification_values,
      backgroundColor: ["#50C878", "#FFA500"]
    }]
  };

  const warehouse_certification_piechart_options = {

  };

  const warehouse_storage_stacked_data = {
    labels: warehouse_storage_labels,
    datasets: [{
      label: "Total Warehouse Storages",
      data: warehouse_storage_values.total,
      backgroundColor: ["#50C878"]
    }, {
      label: "Available Warehouse Storages",
      data: warehouse_storage_values.available,
      backgroundColor: ["#FFA500"]
    }, {
      label: "Used Warehouse Storages",
      data: warehouse_storage_values.used,
      backgroundColor: ["#fd5c63"]
    }]
  };

  const warehouse_storage_stacked_options = {
  };

  const trading_line_data = {
    // labels: registration_data_labels,
    datasets: [{
      label: "Offers",
      data: offers_data_count,
      backgroundColor: "green",
      borderColor: "green",
      pointBorderColor: "green",
      fill: true,
      tension: 0.4
    }, {
      label: "Bids",
      data: bids_data_count,
      backgroundColor: "#FFA500",
      borderColor: "#FFA500",
      pointBorderColor: "#FFA500",
      fill: true,
      tension: 0.4
    }, {
      label: "Offers on Bids",
      data: offers_on_bids_data_count,
      backgroundColor: "#2596be",
      borderColor: "#2596be",
      pointBorderColor: "#2596be",
      fill: true,
      tension: 0.4
    }, {
      label: "Bids on Offers",
      data: bids_on_offers_data_count,
      backgroundColor: "gray",
      borderColor: "gray",
      pointBorderColor: "gray",
      fill: true,
      tension: 0.4
    }]
  };

  const trading_line_options = {
    plugins: {
      legend: true
    },
    scales: {
      y: {
        min: 0,
        // max: 50
      }
    }
  };

  return (
    <>
      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>

      <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
        <div class="flex items-center flex-shrink-0  mr-6">

          <a href='/home' className='flex items-center pl-2.5'>
            <DynamicNavLogo height='50' width='150' />
          </a>
        </div>
        <div class="block lg:hidden">
          <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
            <a href={`/exchange/${entity_id}/${entity_mid}`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white  group">
              Exchange Dashboard
            </a>
          </div>
          <div>
            <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0">Logout</a>
          </div>
        </div>
      </nav>



      <div className="lg-full lg:w-10/12 m-auto">
        <div className="p-4 rounded-lg dark:border-gray-700">

          <div className="relative">
            <div>
              <h5 class="text-3xl mr-3 font-semibold dark:text-white">Data Analytics </h5>
            </div>
            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            {
              activeTab === "Overview" && (
                <>
                  {
                    overview_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Data Anayltics Overview
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          overview_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {overview_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              {
                                overview_data?.entity && (
                                  <>
                                    <div class="mx-auto max-w-full px-4 sm:px-4 lg:px-6">
                                      <div
                                        class="rounded-2xl py-10 px-10 xl:py-16 xl:px-20 bg-gray-50 flex items-center justify-between flex-col gap-16 lg:flex-row">
                                        <div class="w-full lg:w-60">
                                          <h2
                                            class="font-manrope text-4xl font-bold text-gray-900 mb-4 text-center lg:text-left"
                                          >
                                            Entity Statistics
                                          </h2>
                                          <p class="text-sm text-gray-500 leading-6 text-center lg:text-left">
                                            Registered Entity and Entity Profile Details
                                          </p>
                                        </div>
                                        <div class="w-full lg:w-4/5">
                                          <div
                                            class="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between"
                                          >
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.entity?.total_entities}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Entities
                                              </span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.entity?.total_active_entities}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Active Entities
                                              </span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.entity?.total_inactive_entities}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Inactive Entities</span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.entity?.total_entity_profiles}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Entity Profiles</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              {
                                overview_data?.warehouse && (
                                  <>
                                    <div class="mx-auto max-w-full px-4 sm:px-4 lg:px-6">
                                      <div
                                        class="rounded-2xl py-10 px-10 xl:py-16 xl:px-20 bg-gray-50 flex items-center justify-between flex-col gap-16 lg:flex-row">
                                        <div class="w-full lg:w-60">
                                          <h2
                                            class="font-manrope text-4xl font-bold text-gray-900 mb-4 text-center lg:text-left"
                                          >
                                            Warehouse Statistics
                                          </h2>
                                          <p class="text-sm text-gray-500 leading-6 text-center lg:text-left">
                                            Registered Warehouses and Warehouses Data
                                          </p>
                                        </div>
                                        <div class="w-full lg:w-4/5">
                                          <div
                                            class="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between"
                                          >
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.warehouse?.total_warehouses}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Warehouses
                                              </span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {formatNumber(overview_data?.warehouse?.total_capacity)}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Capacity<br /> (MT)</span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {formatNumber(overview_data?.warehouse?.total_used_capacity)}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Used Capacity<br /> (MT)</span
                                              >
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {formatNumber(overview_data?.warehouse?.total_available_capacity)}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Available Capacity<br /> (MT)</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              {
                                overview_data?.ats_data && (
                                  <>
                                    <div class="mx-auto max-w-full px-4 sm:px-4 lg:px-6">
                                      <div
                                        class="rounded-2xl py-10 px-10 xl:py-16 xl:px-20 bg-gray-50 flex items-center justify-between flex-col gap-16 lg:flex-row">
                                        <div class="w-full lg:w-60">
                                          <h2
                                            class="font-manrope text-4xl font-bold text-gray-900 mb-4 text-center lg:text-left"
                                          >
                                            Trading Statistics
                                          </h2>
                                          <p class="text-sm text-gray-500 leading-6 text-center lg:text-left">
                                            Trading Statistics from Trading Platfrom
                                          </p>
                                        </div>
                                        <div class="w-full lg:w-4/5">
                                          <div
                                            class="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between"
                                          >
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.ats_data?.super_bids}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Main Bids
                                              </span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.ats_data?.super_offers}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Main Offers</span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.ats_data?.total_trades_confirmed}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Trades Confirmed</span
                                              >
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {formatNumber(overview_data?.ats_data?.total_trades_amount)}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Trade Amount<br /> (ZMW)</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              {
                                overview_data?.producer && (
                                  <>
                                    <div class="mx-auto max-w-full px-4 sm:px-4 lg:px-6">
                                      <div
                                        class="rounded-2xl py-10 px-10 xl:py-16 xl:px-20 bg-gray-50 flex items-center justify-between flex-col gap-16 lg:flex-row">
                                        <div class="w-full lg:w-60">
                                          <h2
                                            class="font-manrope text-4xl font-bold text-gray-900 mb-4 text-center lg:text-left"
                                          >
                                            Producer Statistics
                                          </h2>
                                          <p class="text-sm text-gray-500 leading-6 text-center lg:text-left">
                                            Registered Producers Statistics
                                          </p>
                                        </div>
                                        <div class="w-full lg:w-4/5">
                                          <div
                                            class="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between"
                                          >
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.producer?.producers}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Total Producers
                                              </span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.producer?.broker_customers}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Producers with Brokers</span>
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.producer?.coop_producers}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Producers in Cooperatives</span
                                              >
                                            </div>
                                            <div class="block">
                                              <div
                                                class="font-manrope font-bold text-4xl text-green-600 mb-3 text-center lg:text-left"
                                              >
                                                {overview_data?.producer?.producer_warehouse_receipts}
                                              </div>
                                              <span class="text-gray-900 text-center block lg:text-left"
                                              >Producers with Warehouse Receipts</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            </>
                        }
                      </>
                  }

                </>
              )
            }{
              activeTab === "Entity Stats" && (
                <>
                  {
                    entity_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Entity Analytics
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          entity_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {entity_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div className='flex h-auto' >
                                <div className='flex-initial z-40 w-2/12 h-auto transition-transform -translate-x-full sm:translate-x-0 ml-x' >
                                  <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                    <span class="sr-only">Open sidebar</span>
                                    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                      <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                    </svg>
                                  </button>

                                  <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 py-8">
                                    <VerticalCustomTabs tabs={tabs_entity_stats} activeTab={entity_stats_tab} setActiveTab={setEntityStatsTab} />
                                  </div>
                                </div>
                                <div className='w-full '>
                                  {
                                    entity_stats_tab === "Registration Stats" && (
                                      <>
                                        <div className='w-11/12 m-auto p-4'>
                                          <h5 class="text-center text-2xl my-4 font-semibold dark:text-white">Registration Statistics</h5>
                                          <br />
                                          <div className='flex-column'>
                                            <div className='flex'>
                                              <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                  </svg>
                                                  <span>
                                                    <span className='text-green-800'>{registrations_data?.length || 0}</span> Entities Registered</span>
                                                </li>
                                              </ul>
                                            </div>
                                            <Line
                                              data={entity_registration_data}
                                              options={entity_registration_options}

                                            />

                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                  {
                                    entity_stats_tab === "Entity Category Stats" && (
                                      <>
                                        <div className='w-11/12 m-auto p-4'>
                                          <h5 class="text-center text-2xl my-4 font-semibold dark:text-white">Entity Category Statistics</h5>
                                          <br />
                                        </div>
                                        <div className='flex-column p-4'>
                                          <h5 class="text-xl my-4 font-semibold dark:text-white ml-8">Entities</h5>
                                          <div className='flex  ml-8'>
                                            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                </svg>
                                                <span>
                                                  <span className='text-green-800'>{registrations_data?.length || 0}</span> Entities Categories</span>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className='w-full flex justify-betweeen'>
                                            <div className='w-8/12 p-4'>
                                              <Bar
                                                data={entity_category_barchart_data}
                                                options={entity_category_barchart_options}
                                              />
                                            </div>
                                            <div className='w-4/12 p-4'>
                                              <Pie
                                                data={entity_category_status_piechart_data}
                                                options={entity_category_status_piechart_options}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className='flex-column p-4'>
                                          <h5 class="text-xl my-4 font-semibold dark:text-white ml-8">Entity Profiles</h5>
                                          <div className='flex  ml-8'>
                                            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                </svg>
                                                <span>
                                                  <span className='text-green-800'>{entity_profile_data?.length || 0}</span> Entity Profiles</span>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className='w-full flex justify-betweeen'>
                                            <div className='w-8/12 p-4'>
                                              <Bar
                                                data={entity_category_horizontal_barchart_data}
                                                options={entity_category_horizontal_barchart_options}
                                              />
                                            </div>
                                            <div className='w-4/12 p-4'>
                                              <Pie
                                                data={entity_profile_status_piechart_data}
                                                options={entity_profile_status_piechart_options}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </>
                        }

                      </>
                  }
                </>
              )
            }{
              activeTab === "Warehouse Stats" && (
                <>
                  {
                    warehouse_stats_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Warehouse Analytics
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          warehouse_stats_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {warehouse_stats_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div className='w-11/12 m-auto p-4'>
                                <h5 class="text-center text-2xl my-2 font-semibold dark:text-white">Warehouse Statistics</h5>
                              </div>
                              <div className='flex-column p-4'>
                                <h5 class="text-xl mb-4 font-semibold dark:text-white ml-8">Warehouses</h5>
                                <div className='flex  ml-8'>
                                  <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                      </svg>
                                      <span>
                                        <span className='text-green-800'>{warehouse_stats_data?.warehouses || 0}</span> Warehouses</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className='w-full flex justify-betweeen'>
                                  <div className='w-8/12 p-4 h-96'>
                                    <Bar
                                      data={warehouse_provinces_horizontal_barchart_data}
                                      options={warehouse_provinces_horizontal_barchart_options}
                                    />
                                  </div>
                                  <div className='w-4/12 p-4 h-96'>
                                    <Pie
                                      data={warehouse_certification_piechart_data}
                                      options={warehouse_certification_piechart_options}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='flex-column p-4'>
                                <h5 class="text-xl mb-4 font-semibold dark:text-white ml-8">Warehouse Storages</h5>
                                <div className='flex  ml-8'>
                                  <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                      </svg>
                                      <span>
                                        <span className='text-green-800'>{warehouse_stats_data?.warehouses ? `${warehouse_stats_data?.total_storage_capacity} MT` : 0}</span> Warehouse Storage</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className='w-full flex justify-betweeen'>
                                  <div className='w-full p-4 h-auto'>
                                    <Bar
                                      data={warehouse_storage_stacked_data}
                                      options={warehouse_storage_stacked_options}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                        }
                      </>
                  }
                </>
              )
            }{
              activeTab === "Trading Platform Stats" && (
                <>
                  {
                    trading_stats_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Trading Platform Analytics
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          trading_stats_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {trading_stats_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div className='w-11/12 m-auto p-4'>
                                <h5 class="text-center text-2xl my-2 font-semibold dark:text-white">Trading Platform Statistics</h5>
                              </div>
                              <div className='flex-column p-4'>
                                <h5 class="text-xl mb-4 font-semibold dark:text-white ml-8">Trading History</h5>
                                <div className='flex  ml-8'>
                                  <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                      </svg>
                                      <span>
                                        <span className='text-green-800'>{trading_stats_data?.total_transactions || 0}</span> Trading Transactions</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className='w-full flex justify-betweeen'>
                                  <div className='w-8/12 p-4 h-96'>
                                    <Line
                                      data={trading_line_data}
                                      options={trading_line_options}

                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                        }

                      </>
                  }
                </>
              )
            }{
              activeTab === "Producer Stats" && (
                <>
                  {
                    producer_stats_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Producer Analytics
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          producer_stats_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {producer_stats_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div className='w-11/12 m-auto p-4'>
                                <h5 class="text-center text-2xl my-2 font-semibold dark:text-white">Producer Statistics</h5>
                              </div>
                              <div className='flex-column p-4'>
                                <h5 class="text-xl mb-4 font-semibold dark:text-white ml-8">Producer Types</h5>
                                <div className='flex  ml-8'>
                                  <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                      </svg>
                                      <span>
                                        <span className='text-green-800'>{producer_stats_data?.producers || 0}</span> Producers</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className='w-full flex justify-betweeen'>
                                  <div className='w-8/12 p-4 h-96'>
                                    <Bar
                                      data={producer_types_barchart_data}
                                      options={producer_types_barchart_options}
                                    />
                                  </div>
                                  <div className='w-4/12 p-4 h-96'>
                                    <Pie
                                      data={producer_warehouse_receipt_piechart_data}
                                      options={producer_warehouse_receipt_piechart_options}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='flex-column p-4'>
                                <h5 class="text-xl mb-4 font-semibold dark:text-white ml-8">Producer Geographical and Commodity Data</h5>
                                <div className='flex  ml-8'>
                                  <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" color='green'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                      </svg>
                                      <span>
                                        <span className='text-green-800'>{Object.keys(producer_stats_data?.producer_province_counts).length || 0}</span> Provinces</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className='w-full flex justify-betweeen'>
                                  <div className='w-8/12 p-4 h-96'>
                                    <Bar
                                      data={producer_provinces_horizontal_barchart_data}
                                      options={producer_provinces_horizontal_barchart_options}
                                    />
                                  </div>
                                  <div className='w-4/12 p-4 h-96'>
                                    <Pie
                                      data={producer_comomdities_piechart_data}
                                      options={producer_comomdities_piechart_options}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                        }

                      </>
                  }
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard;